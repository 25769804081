<template>
  <div class="page faq">

    <v-row class="hide_desktop">
      <v-col cols="12" class="mt-12 hide_desktop">
        <h1 class="hide_desktop">FAQ</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="hide_mobile">
        <v-img src="../assets/plus.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
      </v-col>

      <v-col cols="12" lg="7" md="12" sm="12" class="pt-0">
        <h1 class="hide_mobile">FAQ</h1>
        <p class="mt-4 faq-description">Ficou com alguma dúvida? Veja aqui as perguntas mais frequentes sobre a
          Olimpíada Digital:</p>
      </v-col>

      <v-col class="hide_mobile">
        <v-img src="../assets/double-triangle.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
      </v-col>

      <v-col cols="12" xl="5" lg="5" md="5" sm="5" class="hide_mobile">
        <v-img src="../assets/cloud.png" max-height="300" contain class="cloud_img"></v-img>
      </v-col>
    </v-row>

    <!-- Para usuários -->
    <v-row class="faq-division">
      <v-col cols="12">
        <h1>Para Usuários</h1>
      </v-col>
    </v-row>

    <div class="faq-container">
      <v-row>
        <v-col cols="12">
          <v-expansion-panels accordion flat dark>
            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Como funciona?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                A Olimpíada é uma forma interativa e gamificada de desvendar os assuntos mais recentes do mundo da
                tecnologia. Ao realizar o cadastro no game em 10/06, o usuário deverá escolher uma escola ou instituição
                social. Essa será a sua equipe na Olimpíada. Isso significa que todos os pontos que você conquistar no
                game serão doados para a equipe de sua escolha. Ao longo da competição, um ranking estará disponível
                aqui na página, mostrando em tempo real quais as 3 equipes que possuem a melhor pontuação. O jogo
                funciona numa plataforma interativa, onde os usuários vão respondendo perguntas sobre o tema escolhido
                e, assim, aprendendo com a sua própria jornada. Ao final, você ganha uma medalha e pode consumir quantos
                conteúdos quiser.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Como participar?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Para participar, basta fazer seu cadastro como usuário(a) aqui na página e começar o game. No dia 10/06,
                a plataforma estará liberada para você se cadastrar e começar a jogar. No momento do cadastro, tenha em
                mãos o seu CPF.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Quem pode participar?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Todo mundo pode participar como usuário, mesmo não sendo do estado do Rio de Janeiro. Para isso, basta
                escolher uma equipe entre as disponíveis para doar seus pontos.Caso você não tenha vínculo com nenhuma
                escola ou instituição social, selecione “Não tenho escola ou instituições”.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Preciso fazer parte de alguma escola ou instituição social para participar da Olimpíada?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Não, para esse caso, basta selecionar “Não tenho escola ou instituições”.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Existe premiação para os(as) usuários(as)?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Sim! As premiações são destinadas tanto aos(às) usuários(as) quanto às escolas e instituições sociais.
                Ao se cadastrar, o(a) usuário(a) automaticamente concorre a um laptop gamer que será sorteado ao final
                da olimpíada. E, com os pontos alcançados pelos(as) usuários(as), a escola/instituição escolhida como
                equipe por ele(a) vai concorrer a prêmios, também.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Até quando posso me cadastrar?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                O cadastro começa no dia 10/06. E você pode se cadastrar até o último dia, 30/08.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Existe premiação para escolas e instituições sociais?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Sim! Escolas e instituições, que serão as equipes da olimpíada, receberão:
                - Primeira colocada: Kit projetor, caixa de som
                - Segunda colocada: Kit projetor, caixa de som
                - Terceira colocada: Kit projetor, caixa de som
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                A Olimpíada é nacional?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Por enquanto, não. Só estão habilitadas a participar as escolas e instituições sociais do estado do Rio
                de Janeiro. Mas qualquer usuário pode participar como competidor, de qualquer lugar do Brasil. Para
                isso, basta escolher uma equipe para doar seus pontos. Caso você não tenha vínculo com nenhuma escola ou
                instituição social, selecione “Não tenho escola ou instituições”.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Qual o período da Olimpíada?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                A Olimpíada vai acontecer entre os dias 10 de junho de 2024 e 30 de agosto de 2024. O período de
                cadastro de escolas e instituições sociais será encerrado no dia 05 de junho de 2024.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel final">
              <v-expansion-panel-header class="faq-question">
                Quando e como serão liberados os resultados?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Os resultados serão divulgados a partir do dia 04 de setembro de 2024. As dez primeiras equipes melhores
                colocadas e o usuário ganhador do laptop gamer terão seus nomes divulgados aqui na página e as demais
                instruções serão comunicadas via whatsapp. A promoção seguirá o regulamento divulgado.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>

    <!-- Para escolas e instiuições -->
    <v-row class="faq-division escola">
      <v-col cols="12">
        <h1>Para Escolas/Instituições</h1>
      </v-col>
    </v-row>

    <div class="faq-container">
      <v-row>
        <v-col cols="12">
          <v-expansion-panels accordion flat dark>
            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Como funciona?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                A Olimpíada é uma forma interativa e gamificada de desvendar os assuntos mais recentes do mundo da
                tecnologia. Cada escola ou instituição social será uma equipe. Todos(as) os(as) usuários(as), no ato do
                cadastro, deverão escolher uma equipe para receber os pontos que ele(ela) conquistar ao longo do game.
                Dessa forma, escolas e instituições, promovem uma competição saudável em torno do conhecimento.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Como participar?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Para participar, basta fazer o cadastro da instituição que você representa, aqui na página, e aguardar o
                e-mail de confirmação. A partir daí, é só preencher os dados solicitados e pronto: sua
                escola/instituição social já se torna uma equipe no game. No dia 10/06, a plataforma estará liberada
                para usuários(as) começarem a jogar.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Quem pode participar?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Toda escola e instituição social do estado do Rio de Janeiro está apta a participar da Olimpíada.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Até quando posso cadastrar minha escola/instituição social? </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                O cadastro já está no ar. O cadastro será encerrado no dia 05 de junho de 2024. Até lá, não se preocupe
                que iremos informar por e-mail.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Existe premiação para escolas e instituições sociais?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Sim! Escolas e instituições, que serão as equipes da olimpíada, receberão:
                - Primeira colocada: Kit projetor, caixa de som
                - Segunda colocada: Kit projetor, caixa de som
                - Terceira colocada: Kit projetor, caixa de som
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                A Olimpíada é nacional?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Por enquanto, não. Só estão habilitadas a participar as escolas e instituições sociais do estado do Rio
                de Janeiro. Mas qualquer usuário pode participar como competidor, de qualquer lugar do Brasil. Para
                isso, basta escolher uma equipe para doar seus pontos. Caso você não tenha vínculo com nenhuma escola ou
                instituição social, selecione “Não tenho escola ou instituições”.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel">
              <v-expansion-panel-header class="faq-question">
                Qual o período da Olimpíada?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                A Olimpíada vai acontecer entre os dias 10 de junho de 2024 a 30 de agosto de 2024. O período de
                cadastro de escolas e instituições sociais será encerrado no dia 05 de junho de 2024.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="faq-panel final">
              <v-expansion-panel-header class="faq-question">
                Quando e como serão liberados os resultados?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="faq-explication">
                Os resultados serão divulgados a partir do dia 04 de setembro de 2024. As dez primeiras equipes melhores
                colocadas e o usuário ganhador do laptop gamer terão seus nomes divulgados aqui na página e as demais
                instruções serão comunicadas via whatsapp. A promoção seguirá o regulamento divulgado.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
    <!-- Footer -->
    <Footer></Footer>

  </div>
</template>

<script>
import Footer from '../components/footer.vue'

export default {
  name: 'FAQview',
  components: {
    Footer
  }
}
</script>

<style scoped>
.faq {
  background-color: #5B268E;
  min-height: 100vh;
  color: white;
  background-image:
    radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
    radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
    radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
}

.faq-description {
  text-align: start;
  font-size: 18px;
}

.cloud_img {
  position: absolute;
  right: 0;
}

.faq-division {
  padding-top: 60px;
}

.faq-container {
  width: 70%;
  margin: auto;
}

.faq-panel {
  background-color: transparent !important;
  border-top: 1px solid #FFFFFF;
}

.faq-question {
  font-size: 18px;
}

.faq-explication {
  text-align: start;
  font-size: 18px;
  font-weight: 325;
}

.final {
  border-bottom: 1px solid #FFFFFF
}

.v-expansion-panel-header--active {
  font-weight: bold;
}

@media screen and (max-width: 764px) {
  .faq-division {
    padding-top: 0;
  }

  .escola {
    padding-top: 30px;
  }

  .faq-container {
    width: 90%;
  }
}
</style>
