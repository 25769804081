import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import "@/assets/global.css";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-82CEPQJ52X" },
}, router);

Vue.config.productionTip = false;
Vue.use(VueMask);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
