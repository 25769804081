import axios from "axios";

const API_URL = "https://api.joco.com.br";

const getRanking = async (data) => {
    const resp = await axios.post(`${API_URL}/custom-apis/get-senac-ranking`, data);
    return resp;
};

export {
    getRanking
};
