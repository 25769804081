import axios from "axios";

const API_URL = "https://southamerica-east1-joco-functions.cloudfunctions.net";

const insertSheetItemOlimpiada = async (data) => {
  const resp = await axios.post(
    `${API_URL}/insertSheetItemOlimpiada`,
    data,
    {}
  );
  return resp;
};

const insertSheetItem = async (data) => {
  const resp = await axios.post(`${API_URL}/insertSheetItem`, data, {});
  return resp;
};

const insertSheetItemOlimpiadaPlayer = async (data) => {
  const resp = await axios.post(
    `${API_URL}/insertSheetItemOlimpiadaPlayer`,
    data,
    {}
  );
  return resp;
};
export {
  insertSheetItemOlimpiada,
  insertSheetItem,
  insertSheetItemOlimpiadaPlayer,
};
