<template>
    <div class="page escolas">
        <v-row class="hero justify-end">
            <v-col cols="12" class="pa-10">
                <h1 class="text-center font-weight-bold hero_title">Escolas e Instituições Participantes</h1>
            </v-col>
        </v-row>

        <div class="list-container">
            <div class="flex-right mb-8">
                <v-text-field class="search-input" v-model="filter" label="Procure a escola aqui" outlined rounded dark prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
            </div>
            <div v-if="filteredItems.length > 0" class="item-details mb-10 ml-10 mr-10" cols="12" v-for="(item, index) in filteredItems" :key="index">
                <div>
                    <div class="mb-4"><b>{{ item.escola }}</b></div>
                    <div class="mb-4">{{ item.endereco }} - {{ item.cidade }}</div>
                    <div v-if="item.site"><b>{{ item.site }}</b></div>
                </div>


            </div>

            <div class="ma-10" v-if="filteredItems.length == 0">
                <h3>Não existem escolas cadastradas com esse nome. Por favor, tente um nome diferente.</h3>
            </div>
        </div>


        <Footer></Footer>

    </div>
</template>

<script>
import escolas from "@/lib/Escolas";
import Footer from '../components/footer.vue'

export default {
    name: 'EscolasView',
    components: {
        Footer
    },
    data: () => ({
        escolas: [],
        filter: '',
        loading: false
    }),
    mounted() {
        escolas 
        this.escolas = escolas;
        window.scrollTo(0, 0);
    },
    methods: {

    },

    computed: {
        filteredItems() {
            return escolas.filter(e => e.escola.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()));
        }
    },
}
</script>

<style scoped>
.escolas {
    background-color: #5B268E;
    min-height: 100vh;
    color: white;
    background-image:
        radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
        radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
        radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
}

.row-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.list-container {
    background-image: url('@/assets/form-school.png');
    background-position-x: right;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;
}

.item-details {
    text-align: left;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.22);
    width: 90%;
    max-width: 1200px;
    animation: fadeIn 0.5s;
}

.search-input {
    background-color: rgba(255, 255, 255, 0.15);
    max-width: 350px;
}

.flex-right {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1200px;
    justify-content: flex-end;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10vh)
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 1000px) {
    .list-container {
        background-image: none;
    }
}
</style>
